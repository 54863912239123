<template>
  <div class="message__text">
    <div class="message__content">
      <block-helper-icon />
      <span class="label">{{$t('chat.deleted-message')}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      required: true,
      type: Object,
    },
  },
};
</script>
